import {
  goodsApiCache,
  searchFilterApiCache,
  searchBannerCardApiCache,
} from '../api/bffApi.js'
import { getSearchGenerateBffParams } from '../bffParamsHandle/index.js'
import { getCommonRequestQueue } from './common.js'

const getSearchRequestQueue = ({ requestInfo, newSrcTabPageId }) => {
  const { goodsParams, filterParams, bannerCardParams, cccSeoParams, cccOperationParams } = getSearchGenerateBffParams(requestInfo, newSrcTabPageId)
  const dataKey = ['secondProductInfos']
  const goodsBodyData = {}
  dataKey.forEach((key) => {
    if (!goodsParams[key]) return
    goodsBodyData[key] = goodsParams[key]
    Reflect.deleteProperty(goodsParams, key)
  })
  const requestQueue = {
    // 一些公共请求（多语言等）
    ...getCommonRequestQueue({ requestInfo, cccSeoParams, cccOperationParams }),
    // 商品列表
    goods: goodsApiCache.request({
      type: 'search',
      params: goodsParams,
      data: goodsBodyData,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      withoutAbort: requestInfo.query?.withoutAbort,
    }),
    // 筛选
    filterData: async (cb) => {
      if (requestInfo.type === 'nextpage') return cb(null, {})
      const res = await searchFilterApiCache.request({ type: 'search', params: filterParams }) // 图文导航，分类/属性/tsp筛选，标签云
      cb(null, res)
    },
  }
  if (requestInfo.type !== 'nextpage' && !['store', 'brand'].includes(requestInfo?.query?.search_type)) {
    // 搜索页顶部 ccc/店铺/趋势卡片
    requestQueue.searchBannerCard = searchBannerCardApiCache.request({
      params: bannerCardParams
    })
  }

  return requestQueue
}

export {
  getSearchRequestQueue
}
